<template>
    <div :class="['track', `mode-${$props.mode}`, { 'staff-pick': song.staffFavorite, 'selected': $props.isSelected }]">
        <div class="album-art">
            <img :src="song.artwork || fallbackArtwork" alt="">
        </div>
        <div class="track-info">
            <strong class="track-title">{{ song.title }}</strong>
            <p class="track-artist mb-0">{{ song.artist || 'Unknown Artist' }}</p>
            <div class="timecode" v-if="$props.mode !== 'slide'">{{ song.duration | formatTimeStamp }}</div>
        </div>
        <div class="track-controls">
            <slot name="playButton">
                <button class="play-btn" @click="playPause">
                    <span class="fa-stack">
                        <font-awesome-icon :icon="['fa-regular', amPlaying ? 'fa-circle-notch' : 'fa-circle']"
                            :class="['fa-lg fa-stack-1x', amPlaying ? 'fa-spin' : '']" />
                        <font-awesome-icon :icon="['fa-solid', amPlaying ? 'fa-pause' : 'fa-play']"
                            class="fa-lg fa-stack-1x small-icon" style="transform: scale(.5);" />
                    </span>
                </button>
            </slot>
            <div class="waveform"
                :style="{
                    background: `linear-gradient(90deg, orange 0 ${playerProgress}%, gray ${playerProgress}% 100%)`,
                    maskPosition: `${waveformOffset}% 50%`
                }"
                v-if="$props.mode === 'slide'"></div>
            <div class="timecode" v-if="$props.mode === 'slide'">{{ song.duration | formatTimeStamp }}</div>
            <div class="keywords" v-if="$props.mode === 'row'">
                <template v-for="(keyword, index) in song.musicKeywords">
                    <span :key="keyword.id">{{ keyword.name }}{{ index < (song.musicKeywords.length -1) ? ', ' : ''
                            }}</span>
                </template>
            </div>
            <template v-if="isSelected">
                <v-btn variant="outlined" class="remove-btn p-0" @click="removeSong" :loading="busy" :elevation="0">
                    <font-awesome-icon icon="fa-regular fa-backspace" class="fa-lg" />
                </v-btn>
            </template>
            <template v-else>
                <v-btn variant="outlined" class="add-btn" @click="addSong" :loading="busy" :elevation="0">
                    <font-awesome-icon icon="fa-solid fa-plus" class="fa-lg fa-fw" /> <span class="btn-label">Add
                        Music</span>
                </v-btn>
            </template>
            <slot name="extraButtons"></slot>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import TributeSongService from '@/services/tributeSong.service';
import { remove, map, sumBy, toNumber } from 'lodash';
export default {
    name: 'MusicTrack',
    props: {
        song: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            default: 'row'
        },
        token: {
            type: String,
            required: true
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            }
        },
        waveformOffset() {
            if (this.$props.song) {
                const tmpOffset = sumBy(`${this.$props.song.id}`.split(''), toNumber);
                return tmpOffset * 2.5;
            }
            return 0;
        },
        amPlaying() {
            if (this.$store.state.miniPlayer.currentTrackId && this.$props.song.id === this.$store.state.miniPlayer.currentTrackId) {
                return this.$store.state.miniPlayer.isPlaying;
            }
            return false;
        },
        playerProgress: {
            get() {
                if (this.$props.song.id === this.$store.state.miniPlayer.currentTrackId) {
                    return (this.$store.state.miniPlayer.progress / this.$props.song.duration) * 100;
                }
                return 0;
            }
        }
    },
    filters: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
    },
    data() {
        return {
            apiInstance: null,
            fallbackArtwork: require('@/assets/images/fallback-song-art.png'),
            laurelIcon: require('@/assets/icons/laurel.svg'),
            busy: false,
            isPlaying: false,
        }
    },
    methods: {
        playPause() {
            if (this.amPlaying) {
                this.pause();
            } else {
                this.play();
            }
        },
        play() {
            this.$store.dispatch('miniPlayer/playTrack', this.$props.song.id)
            this.isPlaying = true;
            this.$root.$emit('musicPlay', this.$props.song);
        },
        pause() {
            this.isPlaying = false;
            this.$store.dispatch('miniPlayer/updatePlayingStatus', this.isPlaying);
            this.$root.$emit('musicPause', this.$props.song);
        },
        // Looks like the endpoint to add/remove music requires passing the entire songs payload
        // So adding removing needs all selected songs which we can reference from the vuex store
        // and then other components that need updating should watch the store for changes.
        addSong() {
            this.busy = true;
            // formulate new songs payload
            // post it to api
            // then update the selectedSongs in vuex store
            const newSelectedSongs = [...this.selectedSongs, this.$props.song];
            this.updateSongs(newSelectedSongs);
        },
        removeSong() {
            this.busy = true;
            // console.log('remove Song', this.$props.song);
            const tempSongs = [...this.selectedSongs];
            remove(tempSongs, { id: this.$props.song.id });
            this.updateSongs(tempSongs);
        },
        updateSongs(updatedTracks) {
            // console.log('updateSongs', updatedTracks);
            this.apiInstance.updateSelectedSongs(this.$store.state.tributeVideo.id, { selectedSongs: map(updatedTracks, 'id') })
                .then(_updatedSongResults => {
                    this.$store.dispatch('tributeVideo/updateTributeVideoSelectedSongs', updatedTracks);
                    this.busy = false;
                });
        }
    },
    mounted() {
        this.apiInstance = TributeSongService(this.token);
        this.$root.$on('musicStop', () => {
            this.isPlaying = false;
        });
        this.$root.$on('musicPlay', (song) => {
            if (this.song.id === song.id) {
                this.isPlaying = true;
            } else {
                this.isPlaying = false;
            }
        });
        this.$root.$on('musicStatus', (song, isPlaying) => {
            if (this.song.id === song.id) {
                this.isPlaying = isPlaying;
            }
        });
    },
}
</script>

<style lang="scss" scoped>

.track {
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    background: $light-gray;
    align-items: center;
    transition: all 250ms ease-in-out;

    .album-art {
        position: relative;
        aspect-ratio: 1 / 1;
        max-height: 30%;
        img {
            height: 64px;
            width: 64px;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    .track-info {
        display: flex;
        flex-direction: column;
        
        .track-title,
        .track-artist {
            text-overflow: ellipsis;
            overflow: hidden;

        }

        .track-artist {
            font-size: 0.9rem;
            color: $medium-gray;
        }
    }

    &.staff-pick {
        .album-art {
            width: fit-content;
            &:after {
                content: '';
                background: url('~@/assets/icons/laurel.svg') no-repeat center;
                background-size: 102%;
                filter: drop-shadow(0px 2px 6px rgba(40, 10, 20, 0.7));
                display: block;
                position: absolute;
                z-index: 10;
                inset: 0;
                margin: 0.5rem;
                mask-size: 107%;
                transition: opacity 250ms ease-in-out;
                transition-delay: 1.5s;
            }
        }
    }
    .select-btn {
        @include icon-btn(lighten($btn-orange, 40%), $btn-orange, $btn-orange)
    }
    .play-btn {
        @include icon-btn($btn-orange);
    }
    .stop-btn {
        @include icon-btn(transparent, white);
    }
    .add-btn {
        @include icon-btn(white, $medium-gray, $medium-gray);
        flex: 1 1 10%;
        max-width: 180px;
    }
    .remove-btn {
        @include icon-btn(lighten($btn-red, 35%), $btn-red, lighten($btn-red, 20%));
    }
    &.mode-row {
        .track-info {
            width: 25%;
            flex-wrap: nowrap;
            overflow: hidden;
            @include mobile {
                flex-grow: 1;
            }
            .track-title, 
            .track-artist {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .track-controls {
            flex-grow: 1;
            justify-content: stretch;
            .keywords {
                order: -1;
                flex-grow: 1;
            }
            .timecode {
                order: 0;
            }
            @include mobile {
                flex-grow: 0;
            }
            .play-btn {
                order: 10;
            }
            .waveform {
                flex-grow: 1;
                order: 0;
                @include mobile {
                    display: none;
                }
            }
        }
    }
    &.mode-miniplayer {
        .track-info {
            overflow: hidden;
            flex-grow: 1;
            .track-title,
            .track-artist {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .track-title,
            .track-artist,
            .timecode {
                color: white;
            }
        }
        button {
            background: transparent;
            border: none;
            color: white;
            .btn-label {
                display: none;
            }
        }
        .add-btn,
        .stop-btn,
        .remove-btn {
            background: transparent;
            border: none;
            &:hover {
                background: $btn-orange;
            }

        }
    }
    &.mode-slide {
        aspect-ratio: 1 / 1;
        background-color: #f9fafb;
        padding: 0.9rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    
        .slide-image {
            width: 40%;
            height: 40%;
            object-fit: cover;
            border-radius: 8px;
        }
        .track-info {
            white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                z-index: 2;
        }
        .keywords {
            display: none;
        }
        &.mini {
            max-height: inherit;
            aspect-ratio: inherit;
            flex-direction: row;
            flex-wrap: wrap;
            height: auto;
            border-radius: 10px;

            .track-info {
                flex: 1 1 50%;
            }
            .track-controls {
                flex: 0 1 100%;
                .waveform {
                    flex: 0 1 50%;
                }
            }
        }
    }
    .track-controls {
        gap: 8px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    
        .waveform {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: max(30vw, 300px);

            width: 100%;
            height: 20px;

            content: '\200b';
            background: linear-gradient(90deg, $primary-orange 0 50%, $medium-gray 50% 100%);
            background-repeat: no-repeat;
            mask: url('~@/assets/icons/waveform.svg') no-repeat;
            mask-position: 0 50%;

        }
    }
    
    .timecode {
        color: #9a3412;
        font-weight: bold;
    }
    .keywords {
        color: $medium-gray;
        width: 40%;
        font-size: 0.85rem;
        line-height: 1.3;
        @include mobile {
            display: none;
        }
    }
}

</style>