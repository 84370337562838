<template>
    <div class="tribute-view">
        <TributeStepper
            :currentStep="currentStep"
            :tributeVideo="tributeVideo"
            @change-step="val => handleChangeStep(val)"
        />

        <div class="step-content">
            <div v-if="currentStep > 1" class="preview-player">
                <CreatomatePreview :source="previewSource" :modifications="previewMods" />
            </div>

            <TributeSetup
                v-if="currentStep === 1 && $route.params.slug"
                :serviceSlug="$route.params.slug"
                @tribute-video-created="data => handleTributeCreated(data)"
            />

            <StepNavWrapper
                v-if="currentStep === 2 && tributeVideo.eventId"
                :showPrev="false"
                @prev-step="handleChangeStep(currentStep - 1)"
                @next-step="handleChangeStep(currentStep + 1)"
            >
                <ManagePhotos
                    v-if="currentStep === 2 && tributeVideo.eventId"
                    :eventId="tributeVideo.eventId"
                    @nextStep="this.handleChangeStep(this.currentStep + 1)"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                />
            </StepNavWrapper>

            <StepNavWrapper
                @prev-step="handleChangeStep(currentStep - 1)"
                @next-step="handleChangeStep(currentStep + 1)"
                v-if="currentStep === 3 && tributeVideo.eventId && tributeVideo.id"
            >
                <ManageThemes
                    v-if="currentStep === 3 && tributeVideo.eventId && tributeVideo.id"
                    :eventId="tributeVideo.eventId"
                    :tributeVideo="tributeVideo"
                    @nextStep="this.handleChangeStep(this.currentStep + 1)"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                />
            </StepNavWrapper>

            <StepNavWrapper
                @prev-step="handleChangeStep(currentStep - 1)"
                @next-step="handleChangeStep(currentStep + 1)"
                v-if="currentStep === 4 && tributeVideo"
                :showNext="false"
            >
                <AddMusic
                    v-if="currentStep === 4 && tributeVideo"
                    @nextStep="this.handleChangeStep(this.currentStep + 1)"
                    :eventId="tributeVideo.eventId"
                    :tributeVideo="tributeVideo"
                />
            </StepNavWrapper>
            <StepCreate v-if="currentStep === 5" />
        </div>
        <MiniMusicPlayer :token="token" v-if="token"/>
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import { mapActions } from 'vuex';

import TributeStepper from '@/components/Tribute/TributeStepper.vue';
import TributeSetup from '@/components/Tribute/TributeSetup.vue';
import ManagePhotos from '@/components/Tribute/ManagePhotos.vue';
import ManageThemes from '@/components/Tribute/ManageThemes.vue';
import MiniMusicPlayer from '@/components/Tribute/Music/MiniMusicPlayer.vue';
import CreatomatePreview from '@/components/Misc/CreatomatePreview.vue';
import AddMusic from '@/components/Tribute/AddMusic.vue';
import StepNavWrapper from '../../components/Tribute/StepNavWrapper.vue';
import tributeVideo from '../../store/modules/tribute-video';

export default {
    name: 'TributeView',
    metaInfo: {
        title: 'Tribute Video',
    },
    components: {
        TributeStepper,
        TributeSetup,
        ManagePhotos,
        CreatomatePreview,
        ManageThemes,
        AddMusic,
        StepNavWrapper,
        MiniMusicPlayer,
    },

    data() {
        return {
            currentStep: 1,
            tributeVideo: null,
            apiService: null,
            token: null,
            previewSource: null,
            previewMods: null,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        handleChangeStep(val) {
            if (val <= 0) return;
            if (val > 5) return;

            this.currentStep = val;
        },

        handleTributeCreated(data) {
            this.tributeVideo = data;

            if (this.tributeVideo.eventId) {
                this.handleChangeStep(this.currentStep + 1);
            }

            if (this.tributeVideo.id) {
                this.tryGeneratePreviewJson(this.tributeVideo.id);
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async tryFetchTributeVideo(slug) {
            try {
                this.block(true);
                const resp = await this.apiService.tributeVideo.getTributeVideoByServiceSlug(slug);
                if (resp.data) {
                    this.tributeVideo = resp.data;
                    this.currentStep = 2;
                    this.$store.dispatch('tributeVideo/updateTributeVideo', { ...this.tributeVideo });
                }
            } catch (error) {
                console.error('Error fetching tribute video', error);
            } finally {
                this.block(false);
            }
        },

        async tryGeneratePreviewJson(tributeId) {
            try {
                if (!tributeId) {
                    throw new Error('Invalid tribute video id');
                }

                var resp = await this.apiService.tributeVideo.generatePreviewJson(tributeId);

                if (resp.data.creatomateJson) {
                    const creatomateJson = JSON.parse(resp.data.creatomateJson);

                    if (!creatomateJson.source) throw new Error('Error parsing preview source');

                    this.previewSource = JSON.stringify(creatomateJson.source);

                    if (creatomateJson.modifications) {
                        this.previewMods = JSON.stringify(creatomateJson.modifications);
                    }
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);

        await this.tryFetchTributeVideo(this.$route.params.slug);

        // TODO: setup default template so that preview is ready @ step 2
        if (this.tributeVideo?.id) {
            this.tryGeneratePreviewJson(this.tributeVideo.id);
        }
    },
};
</script>

<style lang="scss" scoped>
.tribute-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #f3f4f6;
}

.step-content {
    width: 100%;
    max-width: 800px;
    margin-top: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: auto;
    transition: height 0.8s ease;
}

.preview-player {
    max-width: 800px;
    width: 100%;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
}
</style>
