<template>
    <div class="step-setup">
        <div class="container">
            <div class="deadline-container">
                <h2>Set a Deadline for Contributions</h2>
                <p>
                    Choose a deadline for contributors to upload their photos and videos. Setting a deadline helps
                    family and friends know when to submit their memories, ensuring everything is included in the final
                    tribute video.
                </p>
                <div class="select-wrapper">
                    <calendar datetime v-model="deadline.date" label="Choose Date and Time" outlined />
                </div>
                <h2>Upload Honoree's Image</h2>
                <p>
                    Upload a photo of your loved one to serve as the thumbnail for this tribute. This image will be used
                    as a preview in various spaces, helping everyone recognize and connect with the tribute.
                </p>
            </div>
            <div class="image-upload">
                <SlideUpload ref="uploader" :videoAllowed="false" :maxFiles="1" @change="files => onImageUpload(files)">
                    <template #activator="{ onClick }">
                        <label @click="onClick" for="image" class="upload-button">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.667 10.667v.666c0 1.105.896 2 2 2h6.667c1.105 0 2-.895 2-2v-.666M10.667 5.333L8 2.667M8 2.667L5.333 5.333M8 2.667v8"
                                    stroke="#6B7280"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span> {{ image.previewURL ? 'Replace Image' : 'Upload Image' }}</span>
                        </label>
                    </template>
                </SlideUpload>

                <div v-if="image.previewURL" class="image-preview">
                    <img :src="image.previewURL" alt="Uploaded Preview" />
                    <button @click="removeImage" class="delete-image">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.833 5.833L15.111 15.952c-.062.872-.788 1.548-1.662 1.548H6.552c-.875 0-1.601-.676-1.663-1.548L4.167 5.833M8.333 9.167v5M11.667 9.167v5M12.5 5.833V3.333c0-.46-.373-.833-.833-.833H8.333c-.46 0-.833.373-.833.833v2.5M3.333 5.833h13.333"
                                stroke="#EF4444"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <button :disabled="loading" @click="createTributeVideo" class="next-button">
                <span class="button-text">Create Tribute Video</span>
            </button>
        </div>
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import Calendar from '@/components/ui/Calendar.vue';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';
import { mapActions } from 'vuex';

export default {
    name: 'TributeSetup',
    data() {
        return {
            deadline: {
                date: null,
                time: null,
            },
            image: {
                previewURL: null,
                file: null,
            },
            loading: false,
            token: null,
            apiService: null,
        };
    },
    props: {
        serviceSlug: {
            type: String,
            required: true,
        },
    },
    components: {
        calendar: Calendar,
        SlideUpload,
    },

    // computed: {

    //     isReady() {
    //          deadline & photo are not required
    //          // todo: add the time check here if the UI is updated to have separate time & date fields
    //          //return this.deadline.date && this.image.previewURL && this.image.file;
    //     },
    // },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        onImageUpload(files) {
            const file = files[0];
            if (file) {
                this.image.file = file;
                const reader = new FileReader();
                reader.onload = e => {
                    this.image.previewURL = e.target.result;
                };

                let fileData = file.data ? file.data : file;
                reader.readAsDataURL(fileData);
            } else {
                this.removeImage();
            }
        },
        uploadPhoto(eventId) {
            if (!eventId || eventId <= 0) {
                throw new Error('Invalid event id');
            }

            const uploader = this.$refs.uploader;

            if (!uploader || typeof uploader.initUpload !== 'function') {
                throw new Error('Error initiating upload');
            }

            return uploader.initUpload(eventId);
        },

        removeImage() {
            this.image = {
                previewURL: null,
                file: null,
            };

            const uploader = this.$refs.uploader;
            if (uploader && typeof uploader.reset === 'function') {
                uploader.reset();
            }
        },

        nextStep() {
            this.$emit('nextStep');
        },
        async createTributeVideo() {
            const slug = this.serviceSlug;
            if (!slug) {
                this.showSnackbar({ messaage: 'Invalid service slug', color: 'error' });
                return;
            }

            try {
                let tributeData = {
                    deadline: this.deadline.date,
                };

                this.loading = true;
                this.block(true);
                const tributeResp = await this.apiService.tributeVideo.createTributeVideoByService(slug, tributeData);

                if (!tributeResp.data.eventId) {
                    throw new Error('Error creating  tribute video');
                }

                const eventId = tributeResp.data.eventId;

                if (this.image.file) {
                    const photoResp = await this.uploadPhoto(eventId);
                }

                this.$emit('tribute-video-created', tributeResp.data);
            } catch (error) {
                console.log(error, 'error creating tribute video');
            } finally {
                this.loading = false;
                this.block(false);
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);
    },
};
</script>

<style lang="scss" scoped>
.step-setup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.deadline-container,
.image-upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

h2 {
    color: #6b7280;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
}

p {
    color: #111827;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.select-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
}

.select-button {
    padding: 10px;
    width: 100%;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    border-radius: 6px;
}

.upload-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;
}

#image {
    display: none;
}

.image-preview {
    position: relative;
    display: inline-block;
}

.image-preview img {
    max-width: 200px;
    max-height: 200px;
    border-radius: 8px;
}

.delete-image {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.next-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    background-color: #ea580c;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
    transition: all 0.3s;
}

.next-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
