<template>
    <div class="manage-container">
        <div class="upper-buttons">
            <button v-if="showPrev" @click="$emit('prev-step')" class="nav-button prev-button">Prev</button>
            <!-- <div></div> -->
            <button v-if="showNext" @click="$emit('next-step')" class="nav-button next-button">Next</button>
        </div>

        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        showPrev: {
            type: Boolean,
            default: true,
        },
        showNext: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.nav-button {
    width: 100px;
    height: 38px;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.next-button {
    background: #ea580c;
    color: white;
    border: none;
    position: absolute;
    right: 0;
    top: -50px;
}

.prev-button {
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    position: absolute;
    left: 0;
    top: -50px;
}

.nav-button:disabled {
    opacity: 0.5;
}

.manage-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // height: calc(100vh - 72px);
    transition: transform 0.8s;
    position: fixed;
    bottom: 10px;
    left: 12px;
    right: 10px;
    width: 99%;
}

.upper-buttons {
    // flex-grow: 1;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin: 12px;
}
</style>
